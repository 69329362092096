<template>
  <div :id=props.index class="lineDiv"></div>
</template>

<script setup>
import * as echarts from "echarts";
import { defineProps, watch, computed,onMounted } from "vue";
const props = defineProps({
  seriesData: {
    type: Array,
    required: true,
  },
  index:{
    type:Number,
    default:'0'
  }
});
const textData = computed(() => {
  return props.seriesData;
});
let myChart
import {sliceDecimal} from '@/utils/tools'
const drawPie = (seriseData,index) => {
  let chartDom = document.getElementById(index);
  myChart =  echarts.init(chartDom);
  const unWarp = (obj) => obj && (obj.__v_raw || obj.valueOf() || obj);
  let options = {
    tooltip: {
      trigger: 'item',
      formatter: function(params){
        let a = sliceDecimal(params.data)
        return a
      },
      padding:[0,5],
      position:[0,0]
    },
    xAxis: {
      show:false,
      type: 'category',
      data: []
    },
    yAxis: {
      show:false,
      type: 'value',
    },
    series: [
      {
        data: seriseData,
        type: 'line',
        symbolSize:7,
        itemStyle:{
          normal:{
            lineStyle:{
              width:3,  // 设置线宽

            }
          }
        }
      }
    ],
  };

  options && unWarp(myChart).setOption(options);
};
onMounted(() => {
  drawPie(props.seriesData,props.index);
});
watch(() => [...props.seriesData], (val) => {
  if (val.length > 0) {
    drawPie(val,props.index);
  }
}, { deep: true });
</script>

<style lang="scss">
.lineDiv {
  flex: 1;
  //width: 100%;
  height:80px;
  width: 180px;
}
</style>
