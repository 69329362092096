<template>
  <div>
    <div style="margin-bottom: 10px" class="flex">
      <el-input
          size="mini"
          placeholder="请输入KOL名称或者标题"
          suffix-icon="el-icon-search"
          v-model="search"
          clearable
          @change="handleSearch"
          style="width: 340px"
      >
        <template #prepend>
          <el-select
              size="mini"
              v-model="searchCol"
              @change="handleSearchCol"
              style="width: 110px"
          >
            <el-option
                v-for="item in searchColOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-input>
      <div class="button_block">
        <el-button
            size="mini"
            type="primary"
            @click="jumpTo()"
            style="margin-left: 10px"
        >负评报告</el-button>
        <el-button
            v-if="!isExpand"
            size="mini"
            @click="expandAllFunc"
            style="margin-left: 10px"
        >全部展开<el-icon class="el-icon--right"><ArrowDown /></el-icon></el-button>
        <el-button
            v-else
            size="mini"
            @click="expandAllFunc"
            style="margin-left: 10px"
        >全部收起<el-icon class="el-icon--right"><ArrowUp /></el-icon></el-button>
      </div>
    </div>
    <el-table
        class="report_stored_table_class"
        ref="sortTable"
        :data="tableData"
        :header-cell-style="headerStyle"
        :cell-style="cellStyle"
        stripe
        :default-sort="{ prop: 'postTime', order: 'descending' }"
        @sort-change="handleSort"
    >
      <el-table-column
          fixed="left"
          type="expand" >
        <template  #default="{row}">
          <el-table class="inline_table" :data="[row]" border="true">
            <el-table-column align="center" label="是否报备" prop="report" />
            <el-table-column align="center" label="ER" prop="engagementRate" >
              <template #default="{ row }">
                {{ row.engagementRate ? row.engagementRate+'%': "&#45;&#45;" }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="CPE" prop="cpe" >
              <template #default="{ row }">
                {{ row.cpe ? row.cpe: "&#45;&#45;" }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="CPTA" prop="cpta" >
              <template #default="{ row }">
                {{ row.cpta ? row.cpta: "&#45;&#45;" }}
              </template>
            </el-table-column>
            <el-table-column
                label="推流费用"
                prop="cost"
                align="center"
            >
              <template #default="scope">
                {{ scope.row.cost ? sliceDecimal(scope.row.cost / 100) : "--" }}
              </template>
            </el-table-column>
            <el-table-column label="ROI" prop="roi" align="center">
              <template #default="scope">
                {{ scope.row.roi ? scope.row.roi : "--" }}
              </template>
            </el-table-column>
            <el-table-column label="供应商" prop="agencyName" align="center">
              <template #default="scope">
                {{ scope.row.agencyName ? scope.row.agencyName : "--" }}
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
          v-for="item in colData"
          :key="item.prop"
          :label="item.label"
          :prop="item.prop"
          :align="item.align"
          :width="item.width"
          :sortable="item.sortable"
          :fixed="item.fixed"
          :formatter="item.formatter"
      >
        <template #header>
          <div v-if="item.label === '平台'">
            <el-select size="mini" v-model="tablePlatform">
              <el-option
                  v-for="item in finSites"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
              >
              </el-option>
            </el-select>
          </div>
          <span v-else>{{ item.label }}</span>
        </template>
      </el-table-column>
      <el-table-column
          :label="'产品维度\n观点占比'"
          prop="summaryPct"
          align="center"
          width="110"
          sortable
      >
        <template #default="scope">
          {{ scope.row.summaryPct ? Number(scope.row.summaryPct*100).toFixed(2)+'%': "&#45;&#45;" }}
        </template>
      </el-table-column>
      <el-table-column
          :label="'负评提醒\n/数量'"
          prop="negativeCommentNotify"
          align="center"
          width="100"
      >
        <template #default="scope">
          <div v-if="scope.row.negativeCommentNotify === 1" style="display: flex;align-items: center;justify-content: center">
            <svg-icon
                class-name="negative_icon"
                :style="{ fontSize: '26px'}"
            />
            <span @click="jumpTo(scope.row)" style="color:darkred;text-decoration: underline;cursor: pointer">{{ scope.row.negativeCommentSum}}</span>
          </div>
          <div v-else style="display: flex;align-items: center;justify-content: center">
            <svg-icon
                class-name="no_negative_icon"
                :style="{ fontSize: '16px',margin: '0 5px'}"
            />
            --
          </div>
        </template>
      </el-table-column>
      <el-table-column
          :label="'较昨日增加\n负评数量'"
          prop="negativeCommentIncrease"
          align="center"
          width="110"
          sortable
      >
        <template #default="scope">
          {{ scope.row.negativeCommentIncrease ?sliceDecimal(scope.row.negativeCommentIncrease): "&#45;&#45;" }}
        </template>
      </el-table-column>
      <el-table-column
          :label="'内容质量\n指数'"
          prop="contentIndex"
          align="center"
          width="110"
          sortable
      >
        <template #default="scope">
          {{ scope.row.contentIndex ? sliceDecimal(scope.row.contentIndex): "&#45;&#45;" }}
        </template>
      </el-table-column>
      <el-table-column
          label="互动量趋势"
          prop="realEngagementTrendList"
          align="center"
          width="180"
      >
        <template #default="scope">
          <lineComponent :seriesData="scope.row.realEngagementTrendList"  :index="scope.$index"/>
        </template>
      </el-table-column>
      <!--      <el-table-column-->
      <!--        label="费用"-->
      <!--        prop="cost"-->
      <!--        align="center"-->
      <!--        width="100"-->
      <!--        sortable-->
      <!--      >-->
      <!--        <template #default="scope">-->
      <!--          {{ scope.row.cost ? sliceDecimal(scope.row.cost / 100) : "&#45;&#45;" }}-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column label="供应商" prop="agencyName" align="center">-->
      <!--        <template #default="scope">-->
      <!--          {{ scope.row.agencyName ? scope.row.agencyName : "&#45;&#45;" }}-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </el-table>
    <div style="text-align: center; margin: 10px 0">
      <el-pagination
          layout="prev, pager, next, total"
          :current-page="page"
          :page-size="pageSize"
          :total="totalSize"
          @current-change="handlePage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script lang="jsx">
import { getStoredList } from "@/api/campaignTrace";
import { mapGetters } from "vuex";
import lineComponent from "./lineComponent";
import {ArrowDown, ArrowUp, Plus, Search} from "@element-plus/icons-vue";
export default {
  name: "storedDataComponent",
  data() {
    return {
      // 表格样式
      headerStyle: {
        backgroundColor: "#F9FCFE",
        borderTopWidth: "1px",
        borderTopColor: "#eee",
        borderTopStyle: "solid",
      },
      cellStyle: {
        fontSize: "12px",
      },
      searchCol: "title",
      searchColOptions: [
        { label: "标题", value: "title" },
        { label: "KOL名称", value: "kolName" },
      ],
      search: "",
      sortCol: "postTime",
      sortDirection: "desc",
      colData: [
        {
          label: "标题",
          prop: "title",
          sortable: false,
          width: 150,
          align: "left",
          fixed: true,
          formatter: (row, column, cellValue) => {
            return (
                <el-tooltip
                    placement="top"
                    enterable={false}
                    content={cellValue}
                    popper-class="report_popper_class"
                >
                  <a class="ellipsis a_href" href={row.url} target="_blank">
                    {cellValue}
                  </a>
                </el-tooltip>
            );
          },
        },
        {
          label: "发布日期",
          prop: "postTime",
          sortable: "custom",
          align: "center",
          fixed: false,
        },
        {
          label: "KOL名称",
          prop: "kolName",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
        },
        {
          label: "平台",
          prop: "platform",
          sortable: false,
          width: 128,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return this.platformToZn(cellValue);
          },
        },
        {
          label: "发帖形式",
          prop: "postType",
          sortable: false,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue === null ? "--" : this.postTypeToZn(cellValue);
          },
        },
        {
          label: "曝光量",
          prop: "realExposure",
          sortable: "custom",
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return this.sliceDecimal(cellValue);
          },
        },
        {
          label: "推流估算",
          prop: "charge",
          sortable: "custom",
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return this.sliceDecimal(cellValue);
          },
        },
        {
          label: "互动量",
          prop: "realEngagement",
          sortable: "custom",
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return this.sliceDecimal(cellValue);
          },
        },
        {
          label: "水量%",
          prop: "fake",
          sortable: "custom",
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue == null ? "--" : cellValue + "%";
          },
        },
        // {
        //   label: "ER",
        //   prop: "engagementRate",
        //   sortable: "custom",
        //   width: 96,
        //   align: "center",
        //   fixed: false,
        //   formatter: (row, column, cellValue) => {
        //     return cellValue == null ? "--" : cellValue + "%";
        //   },
        // },
        // {
        //   label: "CPE",
        //   prop: "cpe",
        //   sortable: "custom",
        //   width: 96,
        //   align: "center",
        //   fixed: false,
        //   formatter: (row, column, cellValue) => {
        //     return cellValue == null ? "--" : cellValue;
        //   },
        // },
        // {
        //   label: "CPI",
        //   prop: "cpi",
        //   sortable: "custom",
        //   width: 96,
        //   align: "center",
        //   fixed: false,
        //   formatter: (row, column, cellValue) => {
        //     return cellValue == null ? "--" : cellValue;
        //   },
        // },
        // {
        //   label: "CPTA",
        //   prop: "cpta",
        //   sortable: "custom",
        //   width: 96,
        //   align: "center",
        //   fixed: false,
        //   formatter: (row, column, cellValue) => {
        //     return cellValue == null ? "--" : cellValue;
        //   },
        // },
      ],
      tableData: [],
      page: 1,
      pageSize: 10,
      totalSize: 0,
      loading: false,
      tempSites: [],
      finSites: [],
      tablePlatform: "all",
      isExpand:false
    };
  },
  props: {
    crowd: {
      type: String,
      default: "32_1",
    },
    campaignId: {
      type: Number,
      default: 0,
    },
    platform: {
      type: String,
      default: "all",
    },
    basicInfo:{
      type:Object,
      default(){
        return {}
      }
    },
    sites: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    ...mapGetters(["month"]),
    params() {
      return {
        ta: this.crowd,
        mon: this.month,
        campaignId: this.campaignId,
        platform: this.tablePlatform,
        sortColumn: this.sortCol,
        sortDirection: this.sortDirection,
        searchColumn: this.searchCol,
        searchWord: this.search,
        page: this.page,
        size: this.pageSize,
      };
    },
  },
  mounted(){
    this.getTableList()
  },
  methods: {
    initTableList() {
      this.searchColumn = "title";
      this.searchWord = "";
      // 调用一次排序事件
      this.$refs.sortTable.sort("postTime", "descending");
    },
    async getTableList() {
      this.loading = true;
      const {
        data: { links, total },
      } = await getStoredList(this.params);
      this.tableData = links;
      this.totalSize = total;
      this.loading = false;
    },
    handleSort({ column, prop, order }) {
      this.sortCol = prop;
      this.sortDirection = order ? order.slice(0, -6) : "desc";
      this.page = 1;
      this.getTableList();
    },
    handlePage(page) {
      this.page = page;
      this.getTableList();
    },
    handleSearchCol() {
      this.search = "";
      this.page = 1;
      this.getTableList();
    },
    handleSearch() {
      this.page = 1;
      this.getTableList();
    },
    jumpTo(row){
      let params = {
        id: btoa(this.campaignId),
        campaignName: this.basicInfo.campaignName,
        duration: this.basicInfo.duration,
        brand:this.basicInfo.brand,
      };
      if(row){
        params.contentId = btoa(row.contentId)
      }
      this.$router.push({
        path: "/negativeReport",
        query: {
          ...params
        },
      });
    },
    expandAllFunc() {
      this.isExpand = !this.isExpand
      this.tableData.forEach((item) => {
        this.$refs.sortTable.toggleRowExpansion(item, this.isExpand);
      });
    },
  },
  watch: {
    crowd() {
      this.initTableList();
    },
    platform() {
      this.initTableList();
    },
    tablePlatform() {
      this.isExpand = false
      this.initTableList();
    },
    sites: {
      handler(val) {
        this.tempSites = JSON.parse(JSON.stringify(val));
        this.tempSites.unshift({
          label: "全平台",
          value: "all",
        });
        this.finSites = this.tempSites;
      },
      deep: true,
      immediate: true,
    },
  },
  components:{
    lineComponent,ArrowUp, ArrowDown,
  }
};
</script>

<style lang="scss">
.report_stored_table_class {
  .a_href {
    text-decoration: underline;
    color: #409eff;
    display: inline-block;
    width: 200px;
  }
}
.cell{
  white-space: pre-line!important;
}
</style>
