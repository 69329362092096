import request, { applicationJson,
    downloadFile,} from "@/utils/axios";

export function getCampaignList(params) {
    return request.get("/campaign/trace/list", params);
}
export function updateCampaignSatus(params) {
    return request.put("/campaign/trace/status", params);
}
export function delCampaign(params) {
    return request.delete("/campaign/trace/info", params);
}
export function getOption(params) {
    return request.get("/campaign/trace/option", params);
}
export function getCampaignBrand(params) {
    return request.get("/campaign/trace/brand", params);
}
export function getCampaignType(params) {
    return request.get("/campaign/trace/type", params);
}
export function getAddList(params) {
    return request.get("/campaign/trace/evaluation/newLinks", params);
}
export function downloadAddDataStatus(parmas) {
    return downloadFile(
        "/campaign/trace/evaluation/newLinks/file",
        parmas,
        "追加状态.xlsx"
    );
}
export function addMoreKolListToCampaign(params) {
    return applicationJson.put("/campaign/trace/kol", params);
}
export function getCompareList(params) {
    return applicationJson.post("/campaign/trace/evaluation/comparison", params);
}
export function getCampaignInfo(params) {
    return request.get("/campaign/trace/info", params);
}
export function putCampaignInfo(params) {
    return applicationJson.put("/campaign/trace/info", params);
}
export function getBoardData(params) {
    return request.get("/campaign/trace/agency/board", params);
}
export function getBoardKolList(params) {
    return request.get("/campaign/trace/kol/agency", params);
}
export function winnerAgency(params) {
    return applicationJson.post("/campaign/trace/quote/winner", params);
}
export function delCamKol(params) {
    return request.delete("/campaign/trace/kol", params);
}

export function getBoardNoAgency(params) {
    return request.get("/campaign/trace/kol/board", params);
}

export function getKolListNoAgency(params) {
    return request.get("/campaign/trace/v2/kol/price", params);
}

// campaign/report
export function downloadReport(params, fileName) {
    return downloadFile("/campaign/trace/report", params, fileName + ".xlsx");
}

export function downLoadCampaignFile(params,fileName) {
    return downloadFile("/campaign/trace/file",params, fileName);
}

export function getOverview(params) {
    return request.get("/campaign/trace/evaluation/overview", params);
}
export function addNostoredData(params) {
    return applicationJson.post("/campaign/trace/evaluation/links", params);
}
export function submitUploadFile(params) {
    return request.post("/campaign/trace/evaluation/links/file/submission", params);
}
export function getCampaignEvaluationPersona(params) {
    return request.get("/campaign/trace/evaluation/persona", params);
}
export function getCampaignPlatformPerformance(params) {
    return request.get("/campaign/trace/evaluation/platform/performance", params);
}
export function submissionUploadSalesFile(params) {
    return request.post("/campaign/trace/evaluation/sales/file/submission", params);
}
export function analyseResult(params) {
    return request.get("/campaign/trace/evaluation/analyseResult", params);
}
export function buildReport(params) {
    return request.put("/campaign/trace/buildReport", params);
}
export function negativeCommentsPageQuery(params) {
    return applicationJson.post("/campaign/trace/evaluation/negativeCommentsPageQuery", params);
}
export function negativeCommentsReport(params,fileName) {
    return downloadFile("/campaign/trace/evaluation/negativeCommentsReport", params,fileName + ".xlsx");
}
export function creatCampaign(params) {
    return applicationJson.post("/campaign/trace/info", params);
}
export function isCampaignNameExit(params) {
    return request.get("/campaign/trace/name/existence", params);
}
// 获取品线列表 /campaign/brandLine
export function getCampaignBrandLine() {
    return request.get("/campaign/trace/brandLine");
}
// 获取产品列表 /campaign/product
export function getCampaignProduct() {
    return request.get("/campaign/trace/product");
}
export function getStoredList(params) {
    return request.get("/campaign/trace/evaluation/links", params);
}
export function getAgency() {
    return request.get("/campaign/agency");
}
