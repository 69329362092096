<template>
  <div style="display: flex">
    <el-menu
        style="border-right: none"
        :default-active="platform"
        @select="handlePlatform"
    >
      <el-menu-item
          v-for="item in formatSites"
          :key="item.value"
          :index="item.value"
          :style="
          item.value === platform
            ? { borderLeft: '4px solid #51A6F0', paddingLeft: '16px' }
            : { borderLeftColor: '#51A6F0' }
        "
      >
        <p style="display: flex; align-items: center">
          <svg-icon
              :class-name="item.value"
              style="font-size: 18px; margin-right: 8px"
              :style="{ fill: item.value === platform ? '#51A6F0' : '#555' }"
          />
          {{ item.label }}({{ item.num }})
        </p>
      </el-menu-item>
    </el-menu>
    <div style="flex: 1" v-loading="loading">
      <div
          style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <el-radio-group v-model="status" size="mini" @change="handleStatus">
          <el-radio-button
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.value"
          >
            {{ item.label }}
          </el-radio-button>
        </el-radio-group>
        <el-button
            size="mini"
            type="primary"
            @click="downloadFile"
            :disabled="JSON.stringify(platformNum) === '{}'"
        >下载后链数据追加状态
        </el-button>
      </div>
      <el-table
          class="report_add_table_class"
          ref="addTable"
          :data="tableData"
          :header-cell-style="headerStyle"
          :cell-style="cellStyle"
          stripe
          :default-sort="{ prop: 'createTime', order: 'descending' }"
          @sort-change="handleSort"
      >
        <el-table-column
            v-for="item in colData"
            :key="item.prop"
            :label="item.label"
            :prop="item.prop"
            :sortable="item.sortable"
            :align="item.align"
            :width="item.width"
            :fixed="item.fixed"
            :formatter="item.formatter"
        />
      </el-table>
      <div style="text-align: center; margin: 10px 0">
        <el-pagination
            layout="prev, pager, next, total"
            :current-page="page"
            :page-size="pageSize"
            :total="totalSize"
            @current-change="handlePage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script lang="jsx">
import { getAddList, downloadAddDataStatus } from "@/api/campaignTrace";
import { mapGetters } from "vuex";

export default {
  name: "addDataComponent",
  data() {
    return {
      // 表格样式
      headerStyle: {
        backgroundColor: "#F9FCFE",
        borderTopWidth: "1px",
        borderTopColor: "#eee",
        borderTopStyle: "solid",
      },
      cellStyle: {
        fontSize: "12px",
      },
      platform: "wechat",
      status: 0,
      statusOptions: [
        { label: "全部", value: 0 },
        { label: "成功", value: 2 },
        { label: "进行中", value: 1 },
        { label: "失败", value: 3 },
      ],
      colData: [
        {
          label: "链接",
          prop: "url",
          width: 320,
          align: "center",
          fixed: false,
          sortable: false,
          formatter: (row, column, cellValue) => {
            if(cellValue.indexOf("http") === -1) {
              cellValue = 'https://'+cellValue
            }
            return (
                <el-tooltip
                    placement="top"
                    enterable={false}
                    content={cellValue}
                    popper-class="report_popper_class"
                >
                  <a class="ellipsis a_href" href={cellValue} target="_blank">
                    {cellValue}
                  </a>
                </el-tooltip>
            );
          },
        },
        {
          label: "KOL名称",
          prop: "kolName",
          width: null,
          align: "center",
          fixed: false,
          sortable: false,
        },
        {
          label: "KOL ID",
          prop: "pkolid",
          width: 146,
          align: "center",
          fixed: false,
          sortable: false,
        },
        {
          label: "费用",
          prop: "cost",
          width: null,
          align: "center",
          fixed: false,
          sortable: false,
          formatter: (row, column, cellValue) => {
            return cellValue ? this.sliceDecimal(cellValue / 100) : null;
          },
        },
        {
          label: "供应商",
          prop: "agencyName",
          width: null,
          align: "center",
          fixed: false,
          sortable: false,
          formatter: (row, column, cellValue) => {
            return cellValue ? cellValue : "--";
          },
        },
        {
          label: "上传时间",
          prop: "createTime",
          width: 165,
          align: "center",
          fixed: false,
          sortable: "custom",
        },
        {
          label: "完成时间",
          prop: "finishTime",
          width: 165,
          align: "center",
          fixed: false,
          sortable: "custom",
        },
        {
          label: "状态",
          prop: "status",
          width: 80,
          align: "center",
          fixed: false,
          sortable: false,
          formatter: (row, column, cellValue) => {
            return cellValue === 3 ? (
                <el-tooltip
                    placement="top"
                    content={row.failReason}
                    enterable={false}
                >
                  <el-tag type="danger" size="mini">
                    失败
                  </el-tag>
                </el-tooltip>
            ) : (
                <el-tag
                    type={cellValue === 1 || cellValue === 0 ? "warning" : "success"}
                    size="mini"
                >
                  {cellValue === 1 || cellValue === 0 ? "进行中" : "成功"}
                </el-tag>
            );
          },
        },
      ],
      tableData: [],
      page: 1,
      pageSize: 10,
      totalSize: 0,
      sortCol: "create_time",
      sortDirection: "desc",
      loading: false,
    };
  },
  props: {
    campaignId: {
      type: Number,
      default: 0,
    },
    platformNum: {
      type: Object,
      default() {
        return {
          bilibili: 0,
          douyin: 0,
          wechat: 0,
          weibo: 0,
          xiaohongshu: 0,
        };
      },
    },
  },
  computed: {
    ...mapGetters(["sites"]),
    formatSites() {
      const list = this.sites.map(({ value, label }) => {
        const num = this.platformNum[value];
        return {
          label,
          value,
          num,
        };
      });
      return list;
    },
    params() {
      return {
        campaignId: this.campaignId,
        status: this.status,
        platform: this.platform,
        page: this.page,
        size: this.pageSize,
        sortColumn: this.sortCol,
        sortDirection: this.sortDirection,
      };
    },
  },
  methods: {
    initTableList() {
      this.$refs.addTable.sort();
      this.status = 0;
      this.platform = "wechat";
      this.page = 1;
      this.sortCol = "create_time";
      this.sortDirection = "desc";
      this.getTableList();
    },
    async getTableList() {
      this.loading = true;
      const {
        data: { links, total },
      } = await getAddList(this.params);
      this.tableData = links;
      this.totalSize = total;
      this.loading = false;
    },
    handlePlatform(platform) {
      this.platform = platform;
      this.page = 1;
      this.getTableList();
    },
    downloadFile() {
      const params = {
        campaignId: this.campaignId,
        status: this.status,
      };
      downloadAddDataStatus(params);
    },
    handlePage(page) {
      this.page = page;
      this.getTableList();
    },
    handleSort({ column, prop, order }) {
      if (prop === "createTime") {
        this.sortCol = "create_time";
      }
      if (prop === "finishTime") {
        this.sortCol = "finish_time";
      }
      this.sortDirection = order ? order.slice(0, -6) : "";
      this.getTableList();
    },
    handleStatus() {
      this.page = 1;
      this.getTableList();
    },
  },
};
</script>

<style lang="scss">
.report_add_table_class {
  margin-top: 10px;
  .a_href {
    text-decoration: underline;
    color: #409eff;
    display: inline-block;
    width: 300px;
  }
  .cell{
    text-overflow: initial;
  }
}
.report_popper_class {
  max-width: 400px;
  word-break: break-all;
}
</style>
