<template>
  <div class="out_block">
    <transition>
      <div class="inner_block" :style="{width:persent + '%'}">
        <span v-if="props.showPersent">{{Math.round(props.length*100)}}%</span>
        <span v-else>{{ sliceDecimal(props.length) }}</span>
      </div>
    </transition>
  </div>
</template>
<script setup>
import {defineProps,onMounted,ref,watch} from 'vue'
const props = defineProps({
  length:{
    type: Number,
    default: 5
  },
  max:{
    type: Number,
    default: 10
  },
  showPersent:{
    type: Boolean,
    default: false
  }
})
const persent = ref(0)
onMounted(()=>{
  setTimeout(()=>{
    persent.value = Math.floor(props.length/props.max*100).toFixed(2)
  }, 1000)
})
watch(()=>props.length,()=>{
  persent.value = 0
  setTimeout(() => {
    persent.value = Math.floor(props.length/props.max*100).toFixed(2)
  },1000)

})
</script>
<style scoped>
.out_block{
  width: 100%;
  height: 20px;
  justify-content: flex-start;
  .inner_block{
    height: 100%;
    background-color: #409eff;
    transition: width 1s;
    text-align: center;
    span{
      color:#fff;
    }
  }
}
</style>